// @file calculates the time in hrs/days/months/yrs between current date and past/future date
import { getUnderscoredCurrentLocale } from '@@/bits/current_locale'
import { __ } from '@@/bits/intl'
import { getTime } from '@@/bits/relative_time'
import strings from '@@/bits/relative_time_strings'
import type { UseTimeAgoMessages } from '@vueuse/core'
import { useTimeAgo } from '@vueuse/core'
import type { ComputedRef } from 'vue'

type RelativeTimeUnits = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'
type RelativeTimeFormat = 'short' | 'long'

const getTranslatedTimestamp = (
  format: RelativeTimeFormat,
  time: number,
  keyName: string,
  elapsedTimeInMs: number,
): string => {
  const localeToUse = getUnderscoredCurrentLocale()
  if (format === 'short') {
    const localeData = strings[`${localeToUse}-short`] ?? strings[localeToUse] ?? strings['en-short']
    const stringOrStringFunction = localeData[keyName]
    const uninterpolatedString =
      typeof stringOrStringFunction === 'function' ? stringOrStringFunction(time) : stringOrStringFunction
    return uninterpolatedString.replace(/%d/i, `${Math.round(time)}`)
  }

  const localeData = strings[localeToUse] ?? strings.en
  const stringOrStringFunction = localeData[keyName]
  const uninterpolatedString =
    typeof stringOrStringFunction === 'function' ? stringOrStringFunction(time) : stringOrStringFunction
  const unprefixedString = uninterpolatedString.replace(/%d/i, `${Math.round(time)}`)
  const stringParts = [unprefixedString]
  if (elapsedTimeInMs > 0) {
    if (localeData.prefixAgo != null) stringParts.unshift(localeData.prefixAgo)
    if (localeData.suffixAgo != null) stringParts.push(localeData.suffixAgo)
  } else {
    if (localeData.prefixFromNow != null) stringParts.unshift(localeData.prefixFromNow)
    if (localeData.suffixFromNow != null) stringParts.push(localeData.suffixFromNow)
  }
  return stringParts.join(' ')
}

const useRelativeTime = function (
  pastOrFutureDate: string | number | Date,
  format: RelativeTimeFormat,
  nowDate: string | number | Date = new Date().getTime(),
): ComputedRef<string> {
  const pastOrFutureDateTimestamp = getTime(pastOrFutureDate)
  const nowDateTimestamp = getTime(nowDate)
  const elapsedTimeInMs = nowDateTimestamp - pastOrFutureDateTimestamp // future elapsed times are negative
  const messages: UseTimeAgoMessages<RelativeTimeUnits> = {
    justNow: getTranslatedTimestamp(format, 1, 'seconds', elapsedTimeInMs), // use seconds for just now
    past: (n) => n,
    future: (n) => n,
    second: (n) => getTranslatedTimestamp(format, n, 'seconds', elapsedTimeInMs), // we only have seconds see relative_time.ts
    minute: (n) => getTranslatedTimestamp(format, n, n > 1 ? 'minutes' : 'minute', elapsedTimeInMs),
    hour: (n) => getTranslatedTimestamp(format, n, n > 1 ? 'hours' : 'hour', elapsedTimeInMs),
    day: (n) => getTranslatedTimestamp(format, n, n > 1 ? 'days' : 'day', elapsedTimeInMs),
    week: (n) => getTranslatedTimestamp(format, Math.floor(elapsedTimeInMs / 86_400_000), 'days', elapsedTimeInMs), // convert time elapsed to days rounded down to get number of days since useTimeAgo rounds weeks e.g. 15 days becomes 2 weeks
    month: (n) => getTranslatedTimestamp(format, n, n > 1 ? 'months' : 'month', elapsedTimeInMs),
    year: (n) => getTranslatedTimestamp(format, n, n > 1 ? 'years' : 'year', elapsedTimeInMs),
    invalid: __('Invalid date'),
  }

  const timeAgo = useTimeAgo(new Date(pastOrFutureDate), {
    messages,
  })

  return timeAgo
}

export { useRelativeTime }
