<script setup lang="ts">
import { getDateStringForDisplay } from '@@/bits/time'
import { useRelativeTime } from '@@/vuecomposables/useRelativeTime'
import { computed } from 'vue'

/**
 * Displays the duration relative to given date/time.
 */
const props = withDefaults(
  defineProps<{
    /**
     * Display the duration from this date/time.
     */
    datetime: string | number | Date
    /**
     * The format to display the relative time in.
     */
    dateformat?: 'short' | 'long'
  }>(),
  {
    dateformat: 'short',
  },
)

const actualTime = computed(() => getDateStringForDisplay(props.datetime))
const relativeTime = useRelativeTime(props.datetime, props.dateformat)
</script>

<script lang="ts">
export default {}
</script>

<template>
  <time :title="actualTime">{{ relativeTime }}</time>
</template>
